export const MAIL = {
   CHRISTIAN: 'christian.hoffmann@vture.de',
   MATHIAS: 'mathias.miertschin@vture.de',
   RICHARD: 'richard.kaestner@vture.de',
   TILLMANN: 'tillmann.patitz@vture.de',
};
export const SOCIALLINKS = {
   LINKEDIN: {
      CHRISTIAN: 'https://www.linkedin.com/in/christian-hoffmann-7551961a/',
      MATHIAS: 'https://www.linkedin.com/in/mathias-miertschin-343482198/',
      RICHARD: 'https://www.linkedin.com/in/richard-kästner-5b540672/',
      TILLMANN: 'https://www.linkedin.com/in/tillmann-patitz-bb519518b/',
      VTURE: 'https://de.linkedin.com/company/vturegmbh/',
   },
   XING: {
      CHRISTIAN: 'https://www.xing.com/profile/Christian_Hoffmann486/cv',
      MATHIAS: 'https://www.xing.com/profile/Mathias_Miertschin/cv',
      RICHARD: 'https://www.xing.com/profile/Richard_Kaestner2/cv',
      //TILLMANN: '',
      VTURE: 'https://xing.com/companies/vturegmbh/',
   },
   TWITTER: {
      VTURE: 'https://twitter.com/vture_media/',
   },
};

export const backgroundIntentMap = {
   'vture-yellow': 'bg-vture-yellow',
   'vture-orange': 'bg-vture-orange',
   'vture-light-cyan': 'bg-vture-light-cyan',
   'vture-dark-cyan': 'bg-vture-dark-cyan',
   light: 'bg-light',
};

export const textIntentMap = {
   'vture-yellow': 'text-vture-blue',
   'vture-orange': 'text-vture-blue',
   'vture-light-cyan': 'text-vture-blue',
   'vture-dark-cyan': 'text-light',
   light: 'text-vture-blue',
};

export const outlineIntentMap = {
   'vture-yellow': 'outline-vture-blue',
   'vture-orange': 'outline-vture-blue',
   'vture-light-cyan': 'outline-vture-blue',
   'vture-dark-cyan': 'outline-light',
   light: 'outline-vture-blue',
};
