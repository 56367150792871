import * as React from 'react';
import { Helmet } from 'react-helmet';

import { Footer } from '../global/Footer';
import CookieConsent from 'react-cookie-consent';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';

import '../../icons';
import '../../scss/style.scss';
import { Navigation } from '../global/Navigation';
import { ScrollToTopButton } from '../global/ScrollToTopButton';

const Layout = ({ children, location }) => {
   const { t } = useTranslation('global');
   const { navigate } = useI18next();

   return (
      <>
         <Helmet htmlAttributes={{ lang: t('locale') }}>
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            <link rel="dns-prefetch" href="//fonts.googleapis.com" />
            <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
            <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
            <meta name="msapplication-TileColor" content="#00aba9" />
            <meta property="og:title" content={t('openGraph.title')} />
            <meta property="og:site_name" content={t('openGraph.siteName')} />
            <meta property="og:description" content={t('openGraph.description')} />
            <meta property="og:url" content="https://www.vture.de/" />
            <meta property="og:locale" content={t('openGraph.locale')} />
            <meta property="og:type" content="website" />
            <meta name="twitter:description" content={t('openGraph.description')} />
            <meta name="twitter:title" content={t('openGraph.title')} />
            <meta itemprop="name" content={t('openGraph.siteName')} />
            <meta itemprop="description" content={t('openGraph.description')} />
         </Helmet>
         <div className="full-background" />
         <Navigation location={location} />
         <div className="main">{children}</div>
         <Footer />
         <ScrollToTopButton />
         <CookieConsent
            flipButtons={true}
            disableButtonStyles={true}
            enableDeclineButton={true}
            buttonText={t('cookieOkButton')}
            buttonClasses="btn btn-outline-vture-light-cyan me-2"
            declineButtonText={t('cookieMoreInfoButton')}
            declineButtonClasses="btn btn-outline-secondary"
            style={{ background: '#2B373B', padding: '15px' }}
            contentStyle={{ margin: 0, paddingRight: '10px' }}
            onDecline={() => navigate('/contact/privacy')}
         >
            {t('cookieText')}
         </CookieConsent>
      </>
   );
};

export default Layout;
