import { config, dom, library } from '@fortawesome/fontawesome-svg-core';

import {
   faLevelDownAlt,
   faLevelUpAlt,
   faComments,
   faHandsHelping,
   faEnvelopeSquare,
   faArrowRight,
   faArrowDown,
   faCaretDown,
   faCaretRight,
   faCheckCircle,
   faArrowCircleRight,
   faHistory,
   faEye,
   faAdjust,
   faQuestionCircle,
   faUsers,
   faSlidersH,
   faKeyboard,
   faHeartbeat,
   faFilePdf,
   faExclamationTriangle,
   faMapSigns,
   faSmile,
   faDraftingCompass,
   faChartLine,
   faSyncAlt,
   faTasks,
   faListOl,
   faMagic,
   faPhotoVideo,
   faGlobe,
   faExchangeAlt,
   faVideo,
   faDesktop,
   faDoorOpen,
   faNetworkWired,
   faRobot,
   faPlug,
   faFileVideo,
   faPalette,
   faGaugeHigh,
   faArrowsTurnToDots,
} from '@fortawesome/free-solid-svg-icons';

import { faLinkedin, faXingSquare, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';

config.autoAddCss = false;

library.add(
   faLinkedin,
   faXingSquare,
   faTwitterSquare,
   faEnvelopeSquare,
   faLevelDownAlt,
   faLevelUpAlt,
   faComments,
   faHandsHelping,
   faArrowRight,
   faArrowDown,
   faCaretDown,
   faCaretRight,
   faCheckCircle,
   faArrowCircleRight,
   faHistory,
   faEye,
   faAdjust,
   faQuestionCircle,
   faUsers,
   faSlidersH,
   faKeyboard,
   faHeartbeat,
   faFilePdf,
   faExclamationTriangle,
   faMapSigns,
   faSmile,
   faDraftingCompass,
   faChartLine,
   faSyncAlt,
   faTasks,
   faListOl,
   faMagic,
   faPhotoVideo,
   faGlobe,
   faExchangeAlt,
   faVideo,
   faDesktop,
   faDoorOpen,
   faNetworkWired,
   faRobot,
   faPlug,
   faFileVideo,
   faPalette,
   faGaugeHigh,
   faArrowsTurnToDots,
);

dom.watch(); // This will kick of the initial replacement of i to svg tags and configure a MutationObserver
