import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Row, Col, Navbar, Nav, Container, Overlay, Popover, ButtonGroup, Button } from 'react-bootstrap';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavigationScrollIndicator } from './NavigationScrollIndicator';
import { useI18next, Link, useTranslation } from 'gatsby-plugin-react-i18next';

import * as navigationClass from './navigation.module.scss';

import logoDark from '../../images/logo_white.svg';
import classNames from 'classnames';

const backgroundColorsMap = {
   products: 'bg-vture-light-cyan',
   solutions: 'bg-vture-orange',
   about: 'bg-vture-yellow',
   contact: 'bg-vture-dark-cyan',
   imprint: 'bg-vture-dark-cyan',
   privacy: 'bg-vture-dark-cyan',
};

export function Navigation({ location }) {
   const { t } = useTranslation('navigation');
   const { pathname } = location;
   const { language, changeLanguage } = useI18next();
   const ref = useRef(null);
   const [target, setTarget] = useState(null);

   const [expanded, setExpanded] = useState(false);
   const [showLanguageSelector, setShowLanguageSelector] = useState(false);
   const [isHomepage, setIsHomepage] = useState(false);

   const hideNavbar = useCallback(() => {
      setExpanded(false);
      setShowLanguageSelector(false);
   }, []);

   const backgroundColor = useMemo(() => {
      const cleanedString = pathname.replace(/\/$/, '');
      const color = backgroundColorsMap[cleanedString.substring(cleanedString.lastIndexOf('/') + 1)];
      if (!color) {
         setIsHomepage(true);
         return 'bg-vture-blue';
      }
      setIsHomepage(false);
      return color;
   }, [pathname]);

   const ExtendedLink = ({ to }) => (
      <Link
         className={classNames(
            navigationClass.navigationItem,
            {
               [navigationClass.active]: pathname.includes(to),
            },
            { [navigationClass.start]: isHomepage },
         )}
         to={`/${to}`}
         onClick={hideNavbar}
      >
         {t(to)}
      </Link>
   );

   return (
      <header className={classnames(navigationClass.header, backgroundColor)}>
         <NavigationScrollIndicator />
         <Container fluid>
            <Row className={navigationClass.navPadding}>
               <Col>
                  <Navbar collapseOnSelect expanded={expanded} onToggle={setExpanded} expand="md" className={classnames('sticky-top')} variant="dark">
                     <Navbar.Brand>
                        <Link to={'/'} onClick={hideNavbar}>
                           <img src={logoDark} alt="VTURE" height={30} className="no-rounded-borders" />
                        </Link>
                     </Navbar.Brand>
                     <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                     <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto ms-auto">
                           <ExtendedLink to={'products'} />
                           <ExtendedLink to={'solutions'} />
                           <ExtendedLink to={'about'} />
                           <ExtendedLink to={'contact'} />
                        </Nav>
                        <Nav>
                           <div>
                              <div ref={ref}>
                                 <Button
                                    variant="outline-light"
                                    size="sm"
                                    onClick={(event) => {
                                       setShowLanguageSelector(!showLanguageSelector);
                                       setTarget(event.target);
                                    }}
                                 >
                                    <FontAwesomeIcon icon="globe" className="me-2" />
                                    {language.toUpperCase()}
                                 </Button>
                                 <Overlay placement="bottom-start" rootClose={true} show={showLanguageSelector} target={target} container={ref}>
                                    <Popover>
                                       <Popover.Body className="p-1">
                                          <ButtonGroup>
                                             <Button
                                                size="sm"
                                                variant={language === 'de' ? 'vture-blue' : 'outline-vture-blue'}
                                                onClick={() => {
                                                   changeLanguage('de');
                                                   hideNavbar();
                                                }}
                                             >
                                                {t('german')}
                                             </Button>
                                             <Button
                                                size="sm"
                                                variant={language === 'en' ? 'vture-blue' : 'outline-vture-blue'}
                                                onClick={() => {
                                                   changeLanguage('en');
                                                   hideNavbar();
                                                }}
                                             >
                                                {t('english')}
                                             </Button>
                                          </ButtonGroup>
                                       </Popover.Body>
                                    </Popover>
                                 </Overlay>
                              </div>
                           </div>
                        </Nav>
                        {expanded && <div className={navigationClass.bottomBorder}>&nbsp;</div>}
                     </Navbar.Collapse>
                  </Navbar>
               </Col>
            </Row>
         </Container>
      </header>
   );
}
