import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import React from 'react';

import * as classes from './scrollToTopButton.module.scss';
import classNames from 'classnames';

export function ScrollToTopButton() {
   const [visible, setVisible] = useState(false);

   const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 300) {
         setVisible(true);
      } else if (scrolled <= 300) {
         setVisible(false);
      }
   };

   const scrollToTop = () => {
      window.scrollTo({
         top: 0,
         behavior: 'smooth',
         /* you can also use 'auto' behaviour
         in place of 'smooth' */
      });
   };

   if (typeof window !== `undefined`) {
      window.addEventListener('scroll', toggleVisible);
   }

   return (
      <div className={classNames(classes.button, { [classes.buttonVisible]: visible })} onClick={scrollToTop}>
         <span className="roboto-slab-bold">
            <FontAwesomeIcon icon="level-up-alt" className="me-2" />
            TOP
         </span>
      </div>
   );
}
