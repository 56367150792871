import React from 'react';
import classnames from 'classnames';
import { useScrollPosition } from './useScrollPosition';

import { container } from './navigationScrollIndicator.module.scss';

export function NavigationScrollIndicator(props) {
   const [state] = useScrollPosition();

   return <div className={classnames(container, 'bg-light')} style={{ width: `${state.value}%` }}></div>;
}
