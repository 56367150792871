import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation, Link } from 'gatsby-plugin-react-i18next';

import logoWhite from '../../images/logo_white.svg';

import * as footerClasses from './footer.module.scss';
import { SOCIALLINKS } from '../../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export function Footer() {
   const { t } = useTranslation('footer');

   return (
      <footer className={footerClasses.footer}>
         <Container fluid>
            <Row className="pt-4 pb-4 gx-3">
               <Col xs="auto">
                  <a href="https://www.vture.de">
                     <img src={logoWhite} alt="VTURE I|O" height={30} style={{ opacity: 0.5 }} className="no-rounded-borders" />
                  </a>
               </Col>
               <Col>
                  <div className={footerClasses.companyInfo}>
                     Software & Solutions
                     <br />
                     <a href="https://www.vture.de" style={{ textDecoration: 'none' }}>
                        www.vture.de
                     </a>
                  </div>
               </Col>
               <Col xs={'auto'}>
                  <div style={{ textAlign: 'right' }}>
                     <a href={SOCIALLINKS.LINKEDIN.VTURE}>
                        <FontAwesomeIcon icon={['fab', 'linkedin']} size="2x" />
                     </a>{' '}
                     <a href={SOCIALLINKS.TWITTER.VTURE}>
                        <FontAwesomeIcon icon={['fab', 'twitter-square']} size="2x" />
                     </a>
                  </div>
               </Col>
            </Row>
            <Row className="pb-5 gx-5">
               <Col sm={12} md={10} xl={4}>
                  <div className={footerClasses.about}>{t('companyInfo')}</div>
               </Col>
               <Col xs={6} sm={4} md={3} xl={2}>
                  <h5 className="roboto-regular">{t('products.title')}</h5>
                  <ul className="list-unstyled text-small">
                     <li>
                        <Link className="text-color-tertiary" to="/products">
                           {t('products.io')}
                        </Link>
                     </li>
                     <li>
                        <Link className="text-color-tertiary" to="/products">
                           {t('products.meet')}
                        </Link>
                     </li>
                  </ul>
               </Col>
               <Col xs={6} sm={4} md={3} xl={2}>
                  <h5 className="roboto-regular">{t('solutions.title')}</h5>
                  <ul className="list-unstyled text-small">
                     <li>
                        <Link className="text-color-tertiary" to="/solutions">
                           {t('solutions.workflow')}
                        </Link>
                     </li>
                     <li>
                        <Link className="text-color-tertiary" to="/solutions">
                           {t('solutions.project')}
                        </Link>
                     </li>
                  </ul>
               </Col>
               <Col xs={6} sm={4} md={3} xl={2}>
                  <h5 className="roboto-regular">{t('about.title')}</h5>
                  <ul className="list-unstyled text-small">
                     <li>
                        <Link className="text-color-tertiary" to="/about">
                           {t('about.company')}
                        </Link>
                     </li>
                     <li>
                        <Link className="text-color-tertiary" to="/about">
                           {t('about.team')}
                        </Link>
                     </li>
                  </ul>
               </Col>
               <Col xs={6} sm={4} md={3} xl={2}>
                  <h5 className="roboto-regular">{t('contact.title')}</h5>
                  <ul className="list-unstyled text-small">
                     <li>
                        <Link className="text-color-tertiary" to="/contact">
                           {t('contact.contact')}
                        </Link>
                     </li>
                     <li>
                        <Link className="text-color-tertiary" to="/contact/imprint">
                           {t('contact.imprint')}
                        </Link>
                     </li>
                     <li>
                        <Link className="text-color-tertiary" to="/contact/privacy">
                           {t('contact.privacy')}
                        </Link>
                     </li>
                  </ul>
               </Col>
            </Row>

            <div className={footerClasses.infotextLeft}>&copy; {new Date().getFullYear()} VTURE GmbH. All rights reserved</div>
         </Container>
      </footer>
   );
}
